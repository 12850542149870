<template>
    <b-card title="Transaction Type">
        <b-form>
            <!-- <b-row>
                <b-col md="3">
                    <b-form-group label="Description">
                        <b-form-input v-model="description" placeholder="Please Enter USD Equivalent"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="2">
                    <b-form-checkbox class="mt-2" v-model="credit">IsCredit</b-form-checkbox>
                </b-col>
                <b-col md="2" class="text-center">
                    <b-button block variant="primary" class="mt-2 mr-2" @click="addUpdateWalletTransactionType">{{
                            addUpdateButton
                    }}
                    </b-button>
                </b-col>
            </b-row>
            <hr> -->

            <b-row>
                <b-col md="12">
                    <b-list-group>
                        <b-list-group-item><b>Transaction Type</b></b-list-group-item>
                        <div v-for="transactionType in transactionTypesOptions" :key="transactionType">
                            <b-list-group-item>{{ transactionType }}</b-list-group-item>
                        </div>

                    </b-list-group>
                </b-col>

            </b-row>

            <!-- <div> -->
                <!-- search input -->
                <!-- <div class="custom-search d-flex justify-content-end">
                    <b-form-group>
                        <div class="d-flex align-items-center">
                            <label class="mr-1">Search</label>
                            <b-form-input v-model="searchTerm" placeholder="Search" type="text"
                                class="d-inline-block" />
                        </div>
                    </b-form-group>
                </div> -->

                <!-- table -->
                <!-- <vue-good-table :line-numbers="true" :columns="columns" :rows="rows" :rtl="direction" :search-options="{
                    enabled: true,
                    externalQuery: searchTerm
                }" :select-options="{
    enabled: false,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
}" :pagination-options="{
    enabled: true,
    perPage: pageLength
}">
                    <template slot="table-row" slot-scope="props"> -->

                        <!-- Column: Name -->
                        <!-- <span v-if="props.column.field === 'updateByUser'" class="text-nowrap">
                            <span class="text-nowrap">
                                {{ props.row.updateByUser.fullName }}
                            </span><br>
                            <span class="text-nowrap">
                                [ {{ props.row.updateByUser.mobileNo }} ]
                            </span>
                        </span> -->

                        <!-- Column: Name -->
                        <!-- <span v-else-if="props.column.field === 'lastUpdated'" class="text-nowrap">
                            <span class="text-nowrap">
                                {{ convertTimeStampToDate(props.row.lastUpdated) }}
                            </span>
                        </span> -->

                        <!-- Column: Name -->
                        <!-- <span v-else-if="props.column.field === 'credit'" class="text-nowrap">
                            <span v-if="props.row.credit === true" class="text-nowrap">
                                Credit
                            </span>
                            <span v-if="props.row.credit === false" class="text-nowrap">
                                Debit
                            </span>
                        </span> -->

                        <!-- Column: Action -->
                        <!-- <span v-else-if="props.column.field === 'action'">
                            <span>
                                <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                                    <template v-slot:button-content>
                                        <feather-icon icon="MoreVerticalIcon" size="16"
                                            class="text-body align-middle mr-25" />
                                    </template>
                                    <b-dropdown-item
                                        @click="editButton(props.row.id, props.row.description, props.row.credit)">
                                        <feather-icon icon="Edit2Icon" class="mr-50" />
                                        <span>Edit</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="deleteWalletTransactionType(props.row.id)">
                                        <feather-icon icon="TrashIcon" class="mr-50" />
                                        <span>Delete</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </span>
                        </span> -->

                        <!-- Column: Common -->
                        <!-- <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                        </span>
                    </template> -->

                    <!-- pagination -->
                    <!-- <template slot="pagination-bottom" slot-scope="props">
                        <div class="d-flex justify-content-between flex-wrap">
                            <div class="d-flex align-items-center mb-0 mt-1">
                                <span class="text-nowrap ">
                                    Showing 1 to
                                </span>
                                <b-form-select v-model="pageLength" :options="['10', '20', '50','100']" class="mx-1"
                                    @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                <span class="text-nowrap"> of {{ props.total }} entries </span>
                            </div>
                            <div>
                                <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number
                                    last-number align="right" prev-class="prev-item" next-class="next-item"
                                    class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                                    <template #prev-text>
                                        <feather-icon icon="ChevronLeftIcon" size="18" />
                                    </template>
                                    <template #next-text>
                                        <feather-icon icon="ChevronRightIcon" size="18" />
                                    </template>
                                </b-pagination>
                            </div>
                        </div>
                    </template>
                </vue-good-table>
            </div> -->
        </b-form>
    </b-card>
</template>
  
<script>
import {
    BAvatar, BCard, BRow, BCol, BForm, BButton, BBadge,BListGroup,BListGroupItem, BFormCheckbox, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '@/store/api'
import moment from 'moment'


export default {
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown, BFormCheckbox,
        BDropdownItem, BCard, BRow, BCol, BForm, BButton,BListGroup,BListGroupItem
    },
    data() {
        return {
            addUpdateButton: 'Add',
            description: '',
            credit: false,
            id: 0,
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: 'Description',
                    field: 'description',
                },
                // {
                //     label: 'Credit / Debit',
                //     field: 'credit',
                // },
                // {
                //     label: 'Last Updated By',
                //     field: 'updateByUser',
                // },
                // {
                //     label: 'Last Updated',
                //     field: 'lastUpdated',
                // },
                // {
                //     label: 'Action',
                //     field: 'action',
                // },
            ],
            rows: [],
            searchTerm: '',
            transactionTypesOptions:[]

        }
    },
    methods: {
        convertTimeStampToDate(value) {
            if (value) {
                return moment(value).format('DD/MM/YYYY hh:mm');
            }else{
                return '';
            }
        },
        getWalletTransactionType() {
            this.id = 0;
            this.description = '';
            this.credit = false;
            this.addUpdateButton = "Add";
            let self = this;

            var axios = require('axios');
            var data = '';

            axios(api.getApi('get', '/masters/getTransactionTypes', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.transactionTypesOptions = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        addUpdateWalletTransactionType() {
            let self = this;
            if (!(self.description == '')) {
                if (this.addUpdateButton == 'Add') {
                    var axios = require('axios');
                    var data = JSON.stringify(
                        {
                            "description": this.description,
                            "credit": this.credit,
                            "updateByUser": {
                                'id': this.$store.state.userDetails.id,
                                'fullName': this.$store.state.userDetails.namePrefix + ' ' + this.$store.state.userDetails.name,
                                'mobileNo': this.$store.state.userDetails.mobileNo
                            }
                        });
                    axios(api.getApi('Post', '/masters/walletTransactionTypes', data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Wallet Transaction Type Added Successfully!`,
                                    icon: 'SaveIcon',
                                    variant: 'success',
                                },
                            })
                            self.getWalletTransactionType();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {
                    let self = this;
                    var axios = require('axios');
                    var data = JSON.stringify(
                        {
                            "description": this.description,
                            "credit": this.credit,
                            "updateByUser": {
                                'id': self.$store.state.userDetails.id,
                                'fullName': self.$store.state.userDetails.namePrefix + ' ' + self.$store.state.userDetails.name,
                                'mobileNo': self.$store.state.userDetails.mobileNo
                            }
                        });
                    axios(api.getApi('put', '/masters/walletTransactionTypes/' + self.id, data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));

                            self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Wallet Transaction Type Updated Successfully!`,
                                    icon: 'SaveIcon',
                                    variant: 'success',
                                },
                            })
                            self.getWalletTransactionType();

                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            } else {
                this.$swal({
                    title: '',
                    text: "Please Enter Description.",
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            }


        },

        deleteWalletTransactionType(id) {
            let self = this;
            this.$swal({
                title: '',
                text: "Are You Sure You Want To Delete!",
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    var axios = require('axios');
                    var data = '';
                    axios(api.getApi('delete', '/masters/walletTransactionTypes/' + id, data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.getWalletTransactionType();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            })
        },

        editButton(id, description, credit) {
            this.id = id;
            this.addUpdateButton = 'Update';
            this.description = description;
            this.credit = credit;

        }
    },
    mounted() {

    },
    computed: {

    },
    created() {
        let self = this;
        self.getWalletTransactionType();
    },
}
</script>
<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';
</style>
<style>
.isActive {
    color: green;
    width: 26px;
    height: 26px;
}

.isInActive {
    color: red;
    width: 26px;
    height: 26px;
}
</style>
   